<template lang="pug">
  .top-bar
    .row.align-items-center
      .col-xl-5.col-lg-12
        .row
          .col-lg-6.col-md-6.col-sm-12.no-right-padding
            p.filter-title {{ $t("inventory_management.shared_inventory_group") }}
            AppDropdown.shared-inventory-group(
              allow-empty
              close-on-select
              searchable
              :loading="inventoryGroupsLoading"
              :value="filters.inventoryGroup"
              :items="inventoryGroups"
              @select="$emit('change:inventory-group', $event)"
              @remove="$emit('change:inventory-group', {})"
              order-direction="keep"
            )
          .col-lg-6.col-md-6.col-sm-12.no-right-padding
            p.filter-title {{ $t("inventory_management.car_class") }}
            AppDropdown.car-class-name(
              v-if="isEmpty(filters.inventoryGroup)"
              :disabled="true"
              v-b-tooltip.hover
              :title="$t('inventory_management.select_inventory_group_first')"
            )
            AppDropdown.car-class-name(
              v-else
              checkbox
              batch-select
              multiple
              expanded
              allow-empty
              close-on-select
              searchable
              :loading="carClassesLoading"
              value-key="id"
              title-key="name"
              :placeholder="$t('inventory_management.select_car_classes')"
              :value="filters.carClasses"
              :items="carClasses"
              order-direction="preorder"
              :is-item-disabled="isItemDisabled"
              :is-close-on-click-outside="isCloseDropdownOnClickOutside"
              @select="$emit('change:car-classes', $event)"
              @closed="$emit('closed:car-classes')"
              :max-select-count="maxSelectCountCarClass"
              :tips="$t('general_settings.car_class_count_tip')"
          )
      .col-xl-7.col-lg-12
        .row
          .col-lg-6.col-md-6.col-sm-12.no-right-padding
            .period-radio
              AppRadioButton.period(
                :value="!filters.monthPickerEnabled"
                @change="enableDatePicker"
              )
              .label
                span {{ $t("inventory_management.period") }}
            .datepicker-wrapper
              AppDatepicker(
                :disabled="filters.monthPickerEnabled"
                :value="filters.dates"
                :range="true"
                :disabled-date="disabledDate"
                :clearable="false"
                @change="changeDates"
              )
              AppButton.today.w-25(
                :disabled="filters.monthPickerEnabled"
                name="today"
                title="inventory_management.today"
                @click="setToday"
              )
          .col-lg-6.col-md-6.col-sm-12
            .period-radio
              AppRadioButton.month(
                :value="filters.monthPickerEnabled"
                @change="enableMonthPicker"
              )
              .label
                span {{ $t("inventory_management.month") }}
            AppMonthPicker(
              :enabled="filters.monthPickerEnabled"
              :initial-date="filters.dates[0]"
              :start-date="minDate"
              :end-date="maxDate"
              @change="changeDates"
            )
    .d-flex.justify-content-between.button-wrapper
      div
        AppButton.inventory-for-long-period.m-l-5(
          title="inventory_management.inventory_long_period.btn_title"
          :disabled="!hasEditPermission('siflp') || isEmpty(inventoryGroups)"
          @click="handleOpenModalForLongPeriod"
        )
        AppButton.inventory-for-future-days.m-l-10(
          title="inventory_management.inventory_for_future.btn_title"
          :disabled="!hasEditPermission('siffd') || isEmpty(inventoryGroups)"
          @click="handleOpenModalForFutureDays"
        )
      div
        AppIconButton.import-inventory(
          useAppIcon
          icon="reply"
          title="inventory_management.import_inventory"
          v-b-tooltip.hover="$t('inventory_management.tooltip.import_inventory')"
          :disabled="!hasEditPermission('manual_inventory_import')"
          @click="handleOpenImportInventoryModal"
        )
        AppIconButton.reimport-inventory(
          useAppIcon
          icon="redo"
          title="inventory_management.resend_inventory"
          v-b-tooltip.hover="$t('inventory_management.tooltip.resend_inventory')"
          @click="handleClickReimportInventory"
        )
        AppSaveButton.m-l-10(
          v-if="hasEditPermission()"
          short
          title="actions.save_changes"
          :disabled="isSavingDisabled || isInventorySyncInProgress"
          @save="$emit('save')"
        )
    SyncInProgressNotification.m-l-5(
      :shown="isInventorySyncInProgress"
      :class="{ visible: isPeriodAndButtonFiltersVisible }"
    )
</template>

<script>
  // mixins
  import withPermissions from "@/mixins/withPermissions"
  import withConfirmation from "@/mixins/withConfirmation"
  import withModal from "@/mixins/withModal"
  import withContactOtaNotification from "@/mixins/withContactOtaNotification"
  import withMaxSelectCountCarClass from "@/mixins/withMaxSelectCountCarClass"
  import withResendNotification from "@/mixins/withResendNotification"

  // misc
  import { isEmpty } from "lodash-es"
  import {
    in300DaysFromCurrentDate,
    startOfPreviousMonthInTimeZone,
    startOfCurrentDateInTimeZone
  } from "@/helpers/dates"
  import { add as dateAdd } from "date-fns"

  // components
  import ImportInventoryModal from "./ImportInventoryModal"
  import ResendInventoryModal from "@/components/InventoryManagement/ResendInventoryModal"
  import SetForLongPeriod from "./SetForLongPeriod"
  import SetForFutureDays from "./SetForFutureDays"

  export default {
    components: {
      AppMonthPicker: () => import("@/components/elements/AppMonthPicker"),
      AppRadioButton: () => import("@/components/elements/AppRadioButton"),
      AppDropdown: () => import("@/components/elements/AppDropdown"),
      AppDatepicker: () => import("@/components/elements/AppDatepicker"),
      AppSaveButton: () => import("@/components/elements/AppButton/Save"),
      AppButton: () => import("@/components/elements/AppButton"),
      SyncInProgressNotification: () => import("@/pages/InventoryManagement/SyncInProgressNotification.vue"),
      AppIconButton: () => import("@/components/elements/AppButton/WithIcon/Other")
    },

    mixins: [
      withPermissions,
      withConfirmation,
      withModal,
      withMaxSelectCountCarClass,
      withContactOtaNotification,
      withResendNotification
    ],

    props: {
      filters: {
        type: Object,
        default: () => new Object()
      },
      inventoryGroups: {
        type: Array,
        default: () => new Array()
      },
      inventoryGroupsLoading: {
        type: Boolean,
        default: false
      },
      carClasses: {
        type: Array,
        default: () => new Array()
      },
      carClassesLoading: {
        type: Boolean,
        default: false
      },
      isSavingDisabled: {
        type: Boolean,
        default: true
      },
      isChangedInventories: {
        type: Boolean,
        default: false
      },
      isInventorySyncInProgress: {
        type: Boolean,
        default: false
      },
      isPeriodAndButtonFiltersVisible: {
        type: Boolean,
        default: false
      },
      inProgressReferenceItemIds: {
        type: Array,
        default: () => new Array()
      },
      processingInventoryGroupIds: {
        type: Array,
        default: () => []
      },
      isCloseDropdownOnClickOutside: {
        type: Boolean,
        default: true
      }
    },

    created() {
      if (this.useContactOtaNotification) {
        this.handleOpenImportInventoryModal = () => {
          this.showContactOtaNotification(this.openImportInventoryModal)
        }
      } else {
        this.handleOpenImportInventoryModal = this.openImportInventoryModal
      }
    },

    computed: {
      minDate() {
        return startOfPreviousMonthInTimeZone()
      },

      maxDate() {
        return in300DaysFromCurrentDate()
      },

      otas() {
        return this.$store.getters.translatedOtaList
      }
    },

    methods: {
      isEmpty,

      enableDatePicker() {
        this.$emit("change:month-picker-enabled", false)
      },

      enableMonthPicker() {
        this.$emit("change:month-picker-enabled", true)
      },

      isItemDisabled({ selectable }) {
        return !selectable
      },

      disabledDate(date) {
        return date < this.minDate || date > this.maxDate
      },

      setToday() {
        this.changeDates([startOfCurrentDateInTimeZone(), dateAdd(startOfCurrentDateInTimeZone(), { months: 1 })])
      },

      changeDates(dates) {
        this.$emit("change:dates", dates)
      },

      handleOpenModal({ title, component, props = {}, emitClose = false, useConfirm = false }) {
        this.$openModal({
          size: "large",
          closeOnEsc: false,
          closeOnClick: false,
          title,
          component,
          props,
          emitClose,
          useConfirm
        })
      },

      openImportInventoryModal() {
        this.$openModal({
          title: this.$t("inventory_management.inventory_import.import_inventory_data"),
          component: ImportInventoryModal,
          size: "large",
          props: {
            inventoryGroups: this.inventoryGroups,
            inProgressReferenceItemIds: this.inProgressReferenceItemIds
          }
        })
      },

      handleClickReimportInventory() {
        this.showResendNotification(this.openReimportInventoryModal)
      },

      openReimportInventoryModal() {
        this.$openModal({
          title: this.$t("inventory_management.inventory_import.resend_inventory_data"),
          component: ResendInventoryModal,
          size: "large",
          props: {
            references: this.inventoryGroups,
            referenceType: "inventory_group",
            processingReferenceIds: this.processingInventoryGroupIds,
            otaList: this.otas,
            resendAction: params => this.$emit("resend-inventory", params)
          }
        })
      },

      handleOpenModalForLongPeriod() {
        this.$conditionalConfirm({
          useConfirm: this.isChangedInventories,
          title: this.$t("inventory_management.continue_without_save"),
          handler: () => {
            this.$emit("reset-changed-inventories")
            this.handleOpenModal({
              title: this.$t("inventory_management.inventory_long_period.modal_title"),
              component: SetForLongPeriod,
              props: {
                inventoryGroups: this.inventoryGroups
              },
              emitClose: true
            })
          }
        })
      },

      handleOpenModalForFutureDays() {
        this.handleOpenModal({
          title: this.$t("inventory_management.inventory_for_future.auto_set_inventory_for_future_days"),
          component: SetForFutureDays,
          useConfirm: true,
          props: {
            inventoryGroups: this.inventoryGroups
          }
        })
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/mixins/common.sass"
  @import "@/assets/styles/variables.sass"

  .top-bar
    +filter-title

    .no-right-padding
      padding-right: 0

    .period-radio
      margin-left: 5px
      display: flex
      justify-content: left

      .app-radio-button
        margin-top: 2px

        ::v-deep
          .big-circle
            border: 1px solid $default-gray
            width: 15px
            height: 15px

          .small-circle
            width: 9px
            height: 9px

      .label
        +filter-title-styles

    .today
      color: $default-purple
      border: 1px solid $default-purple
      height: 38px
      padding: 5px 10px
      width: 100px !important

    .inventory-for-long-period
      background: $default-purple
      color: $default-white
      padding: 0 20px
      width: 112px

    .inventory-for-future-days
      background: #2e9fad
      color: $default-white
      padding: 0 20px
      width: 112px

    .notification
      margin-top: 5px
      margin-bottom: 0

    .datepicker-wrapper
      display: flex
      padding: 6px

      .mx-datepicker-range
        width: 100%

    .reimport-inventory
      width: 150px

    ::v-deep
      .import-inventory
        background-color: $default-white
        color: $default-purple
        border: 1px solid $default-purple
        font-size: 0.8rem
        font-weight: normal
        width: 150px
        height: 33px
        padding: 5px 10px
        margin-right: 10px

        svg
          path
            fill: $default-purple

    .save-inventories-row
      align-items: center
      display: flex
      justify-content: space-between

      p
        font-size: 0.8rem
        margin: 0
    .constraint-tooltip
      margin-right: 10px
</style>

import { render, staticRenderFns } from "./ImportInventoryModal.vue?vue&type=template&id=687be706&scoped=true&lang=pug&"
import script from "./ImportInventoryModal.vue?vue&type=script&lang=js&"
export * from "./ImportInventoryModal.vue?vue&type=script&lang=js&"
import style0 from "./ImportInventoryModal.vue?vue&type=style&index=0&id=687be706&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "687be706",
  null
  
)

export default component.exports
<template lang="pug">
  .set-for-long-period
    WarningAlert
    .filters
      .dates
        DatepickerFilter(
          :value="filters.date"
          :min-date="minDate"
          :max-date="maxDate"
          @change="filters.date = $event"
        )
        WeekDayFilter(
          :weekdays="filters.weekdays"
          :holiday-options="filters.holidayOptions"
          @input:weekdays="filters.weekdays = $event"
          @input:holiday-options="filters.holidayOptions = $event"
        )
      .dropdowns
        DropdownFilters(
          :filters="filters"
          :inventory-groups="inventoryGroups"
          :car-classes="carClasses"
          :car-classes-loading="carClassesLoading"
          @select:inventory-group="handleSelectInventoryGroup"
          @select:car-classes="handleSelectCarClasses"
        )
    .settings
      SettingList(
        :settings="settings"
        @change="handleChangeSettings"
      )
    .apply-changes-button
      AppButton(
        :disabled="isEmpty(settings)"
        title="inventory_management.inventory_long_period.save_changes"
        @click="applyChanges"
      )
</template>

<script>
  // mixins
  import withStoreModule from "@/mixins/withStoreModule"
  import withConfirmation from "@/mixins/withConfirmation"

  // store modules
  import carClassMatchingModule from "@/config/store/shared_inventory_management/car_classes"

  // misc
  import { startOfCurrentDateInTimeZone, in300DaysFromCurrentDate } from "@/helpers/dates"
  import { toObjectBy } from "@/helpers/common"
  import { isEmpty, reduce } from "lodash-es"
  import { bus } from "@/config"

  const minDate = startOfCurrentDateInTimeZone()
  const maxDate = in300DaysFromCurrentDate()

  const carClassMatchingMixin = withStoreModule(carClassMatchingModule, {
    name: "sharedCarClassMatchingLongPeriod",
    readers: { carClassesLoading: "loading", carClasses: "items" },
    actions: { fetchCarClasses: "FETCH_ITEMS" }
  })

  export default {
    components: {
      WarningAlert: () => import("@/components/InventoryManagement/SetInventoryForLongPeriod/WarningAlert"),
      DatepickerFilter: () => import("@/components/InventoryManagement/SetInventoryForLongPeriod/DatepickerFilter"),
      WeekDayFilter: () => import("@/components/InventoryManagement/SetInventoryForLongPeriod/WeekDayFilter"),
      DropdownFilters: () => import("./DropdownFilters"),
      SettingList: () => import("./SettingList"),
      AppButton: () => import("@/components/elements/AppButton")
    },

    mixins: [carClassMatchingMixin, withConfirmation],

    props: {
      inventoryGroups: Array
    },

    data() {
      return {
        minDate,
        maxDate,
        filters: {
          inventoryGroup: {},
          carClasses: [],
          date: [minDate, maxDate],
          weekdays: {
            monday: true,
            tuesday: true,
            wednesday: true,
            thursday: true,
            friday: true,
            saturday: true,
            sunday: true
          },
          holidayOptions: {
            holiday: true,
            tomorrow_holiday: true
          }
        },
        settings: {}
      }
    },

    computed: {
      groupedCarClasses({ carClasses }) {
        return toObjectBy("id", carClasses)
      },

      otas() {
        return this.$store.getters.translatedOtaList
      },

      defaultSettingsObject({ otas }) {
        return {
          shared_inventory_limit: null,
          sale_settings: reduce(
            otas,
            (obj, { id }) => {
              obj[id] = {
                add_sale_stop: false,
                delete_sale_stop: false
              }

              return obj
            },
            {}
          )
        }
      }
    },

    methods: {
      isEmpty,

      handleSelectInventoryGroup(inventoryGroup) {
        this.$conditionalConfirm({
          useConfirm: !isEmpty(this.settings),
          handler: () => {
            this.filters = { ...this.filters, inventoryGroup, carClasses: [] }
            this.actualizeSettings()

            this.fetchCarClasses({ inventory_group_id: inventoryGroup.id })
          }
        })
      },

      handleSelectCarClasses(carClasses) {
        this.filters.carClasses = carClasses
        this.actualizeSettings()
      },

      handleChangeSettings(settings) {
        this.settings = settings
      },

      actualizeSettings() {
        const updatedSettings = reduce(
          this.filters.carClasses,
          (obj, { order, id }) => {
            const setting = this.settings[order] || this.defaultSettingsObject
            obj[order] = { ...setting, carClass: this.groupedCarClasses[id] }

            return obj
          },
          {}
        )

        this.settings = updatedSettings
      },

      applyChanges() {
        bus.emit("set-inventory-long-period", { filters: this.filters, settings: this.settings })
        this.$emit("close")
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .set-for-long-period
    margin-bottom: -$modal-padding
    width: 800px

    .filters
      margin-bottom: 25px

      .dates
        align-items: flex-start
        display: flex
        justify-content: space-between
        margin-bottom: 20px
        height: 85px

    .apply-changes-button
      text-align: right
      margin-top: 20px

      ::v-deep
        .app-button
          background: $default-purple
          color: $default-white
          font-size: 0.8rem
          text-transform: uppercase
</style>

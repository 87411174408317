<template lang="pug">
  ImportInventoryModal(
    :is-import-disabled="isImportDisabled"
    :import-inventory-available-loading="importInventoryAvailableLoading"
    @start-import="importInventory"
    @close="$emit('close')"
  )
    template(v-slot:import-filters)
      .import-filters
        .inventory-groups
          .title {{ $t("inventory_management.inventory_import.inventory_group") }}
          AppDropdown(
            :autofocus="false"
            multiple
            batch-select
            allow-empty
            searchable
            checkbox
            only-body
            :value="filters.inventoryGroups"
            :items="inventoryGroups"
            @select="filters.inventoryGroups = $event"
            order-direction="keep"
          )
        .car-classes
          .title {{ $t("inventory_management.inventory_import.car_class") }}
          AppDropdown(
            :autofocus="false"
            multiple
            batch-select
            allow-empty
            searchable
            checkbox
            only-body
            :loading="carClassesLoading"
            :value="filters.carClasses"
            :items="carClassesItems"
            @select="filters.carClasses = $event"
          )
</template>

<script>
  // // mixins
  import modalCommonMixin from "@/components/InventoryManagement/ImportInventoryModal/modalCommonMixin"

  // misc
  import { isEmpty, groupBy, filter, reduce, intersection, map } from "lodash-es"

  export default {
    mixins: [modalCommonMixin],

    props: {
      inventoryGroups: Array,
      inProgressReferenceItemIds: {
        type: Array,
        default: () => new Array()
      }
    },

    data() {
      return {
        filters: {
          inventoryGroups: [],
          carClasses: []
        }
      }
    },

    computed: {
      isSelectedInventoryGroupsExportInProgress({ filters: { inventoryGroups }, inProgressReferenceItemIds }) {
        return !isEmpty(intersection(map(inventoryGroups, "id"), inProgressReferenceItemIds))
      },

      isImportDisabled({ isSelectedInventoryGroupsExportInProgress, importAvailable, isInvalidSelection }) {
        return isSelectedInventoryGroupsExportInProgress || !importAvailable || isInvalidSelection
      },

      isInvalidSelection({ filters }) {
        return isEmpty(filters.inventoryGroups) || isEmpty(filters.carClasses)
      },

      importFilters({ filters, selectedShops }) {
        return {
          shops: selectedShops,
          carClasses: filters.carClasses,
          inventoryGroups: filters.inventoryGroups
        }
      },

      selectedShops({ filters, groupedShops }) {
        return reduce(
          filters.inventoryGroups,
          (obj, { id }) => {
            const shops = groupedShops[id]

            if (shops) obj = [...obj, ...shops]

            return obj
          },
          []
        )
      },

      groupedShops({ shopsItems }) {
        return groupBy(filter(shopsItems, "inventory_group.id"), "inventory_group.id")
      }
    },

    methods: {
      importInventory() {
        this.importInventoryAction(this.importFilters)

        this.$emit("close")
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/mixins/inventory-management.sass"

  +import-inventory-modal-filters
</style>

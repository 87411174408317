<template lang="pug">
  .set-for-future-days
    SetForFutureDays(
      :references="inventoryGroups"
      :inventory-fields="['shared_inventory_limit', 'sale_stop']"
    )
</template>

<script>
  export default {
    components: {
      SetForFutureDays: () => import("@/components/InventoryManagement/SetForFutureDays")
    },

    props: {
      inventoryGroups: {
        type: Array,
        default: () => new Array()
      }
    }
  }
</script>

<style lang="sass" scoped></style>
